var $ = require("jquery");
var breakpoint = require('./app-breakpoints');
var slick = require("slick");
var _gridSize = breakpoint().match(/(\d+)/g);
var _winSize = $(window).width();
var	_gridLabel = breakpoint().replace(/\d+/g, '').replace(" ", "").replace(/\"/g, "");

function initMap(mapinfo) {
  var myLatLng = {lat: mapinfo.datalat, lng: mapinfo.datalng};
  var center_map = myLatLng;
  var styles = [
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#444444"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "color": "#f2f2f2"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 45
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#39859C"
            },
            {
                "visibility": "on"
            }
        ]
    }
  ];
  if (mapinfo.center){
    center_map = mapinfo.center;
  }
  var map = new google.maps.Map(document.getElementById(mapinfo.id), {
    zoom: mapinfo.zoom,
    disableDefaultUI: mapinfo.disableDefaultUI,
    draggable: mapinfo.draggable,
    draggableCursor: mapinfo.draggableCursor,
		mapTypeId: mapinfo.maptype,
    center: center_map,
    styles: styles
  });
  if (mapinfo.disableDefaultUI == false) {
    var image = document.location.origin + '/app/themes/wp-piscine/img/pin_default.png';

    var marker = new google.maps.Marker({
      position: myLatLng,
      map: map,
      icon: image
    });
    google.maps.event.addListener(marker, 'click', function() {
      window.open('https://www.google.com/maps/dir/Current+Location/'+mapinfo.datalat+',%20'+mapinfo.datalng);
    });
  }
}
window.preparMap = function(){
  bounds = new google.maps.LatLngBounds();
  infowindow = new google.maps.InfoWindow();
  var datalat = "";
  var datalng = "";
  var id = "";
  var disableDefaultUI = false;
  var draggable = true;
  var draggableCursor = '';
  var zoom = 10;
  var marker = "default";
  var maptype = "roadmap";
  var center;
  $('.vy_google_map').each(function(){
    datalat = $(this).data('lat');
    datalng = $(this).data('lng');
    if ($(this).attr('data-zoom')) {
      zoom = parseInt($(this).data('zoom'));
    }
    if ($(this).attr('data-center-lat') && $(this).attr('data-center-lng')) {
      center = {lat: $(this).data('center-lat'), lng: $(this).data('center-lng')};
    }
    if ($(this).attr('data-marker')) {
      marker = $(this).data('marker');
    }
		if ($(this).attr('data-maptype')) {
      maptype = $(this).data('maptype');
    }
    id = $(this).attr('id');
    if ($(this).data('disableui') == true) {
      disableDefaultUI = $(this).data('disableui');
      draggable = false;
      draggableCursor = 'default';
    }

    // console.log('---vy_google_map---');
    // console.log('ID: ' + id);
    // console.log('LAT: ' + datalat);
    // console.log('LNG: ' + datalng);
    // console.log('center: ');
    // console.dir(center);
    // console.log('zoom: ' + zoom);
    // console.log('disableDefaultUI: ' + disableDefaultUI);
    // console.log('draggable: ' + draggable);
    // console.log('draggableCursor: ' + draggableCursor);
    // console.log('marker: ' + marker);
		var mapinfo = {datalat:datalat, datalng:datalng, id:id, zoom:zoom, disableDefaultUI:disableDefaultUI, draggable:draggable, draggableCursor:draggableCursor, marker:marker, center:center, maptype:maptype};
    initMap(mapinfo);
  });
}

function loadScript(callback) {
  var script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = 'https://maps.googleapis.com/maps/api/js?v=3.exp' +
      '&callback='+ callback + '&key=AIzaSyBshim-nFrMzhXvo_3_ZvZjAqzkhHfIOq8';
  document.body.appendChild(script);
}
if (document.getElementsByClassName("vy_google_map").length > 0) {
    window.onload = loadScript('preparMap');
}
$(function() {
  // var basebtntext = $('.frm_final_submit').text();
  // $('#field_subtotal').on('change', function(){
  //   console.log($(this).val());
  //   if($(this).val() > 0){
  //     $('.frm_final_submit').text('Payer et envoyer la demande');
  //   }else{
  //     $('.frm_final_submit').text(basebtntext);
  //   }
  // });

  if($('.vy_customform .frm_forms').length){
    function getSummary(){
      var summary = [];
      $('ul.summary').empty();
      $('.vy_customform_services_primary input[type="checkbox"]').each(function(){
        if(this.checked) {
          var text = $(this).parent('label').find('> span > span').html();
          summary.push(text);
        }
      });
      $('.vy_customform_options input[type="checkbox"]').each(function(){
        if(this.checked) {
          var text = $(this).parent('label').find(' > span').html();
          summary.push(text);
        }
      });
      // console.log(summary); 
      $.each(summary, function(index,value) { 
        $('ul.summary').append('<li>'+value+'</li>')
      });
    }
    $('.vy_customform .frm_forms input[type="checkbox"]').on('change', function() {
      // console.log('change');
      getSummary();
    });
  }

    $('.carousel').slick({
      autoplay: false,
      responsive: false,
      fade: true,
      dots: false,
      autoplaySpeed: 8500,
      arrows: false
    });

	  $('.vy_slideshow_actions_enlarge').on('click', function (e) {
	    $('.vy_slideshow_item.uk-active a')[0].click();
	    return false;
	  });

	  $('.vy_detail_gallery_agrandir').on('click', function (e) {
	    $('.vy_detail_gallery .vy_gallery_item.uk-active a')[0].click();
	    return false;
	  });

    $('.vy_testimonials_next').on('click', function (e) {
	    $('.vy_testimonials_item.uk-active > .vy_testimonials_item_next')[0].click();
	    return false;
	  });
    $('.vy_testimonials_previous').on('click', function (e) {
	    $('.vy_testimonials_item.uk-active > .vy_testimonials_item_previous')[0].click();
	    return false;
	  });
    if ( $( ".vy_testimonials" ).length ) {
      function nextSwitcher() {
        // here comes the function to click the next tab
        $('.vy_testimonials_item.uk-active > .vy_testimonials_item_next')[0].click();

        // and here you call this function again
        switcherTimeOut = setTimeout(nextSwitcher, 5000);
      }
      // this is to call the function for the first time
      switcherTimeOut = setTimeout(nextSwitcher, 5000);
      $('.vy_testimonials > div').mouseover( function () {
        clearTimeout(switcherTimeOut);
      });
      $('.vy_testimonials > div').mouseout( function () {
        switcherTimeOut = setTimeout(nextSwitcher, 5000)
      });
    }

	// https://stackoverflow.com/questions/20928915/jquery-triggerclick-not-working
	// $(window).scroll(function() {
	// 	if(_gridLabel != "smartphone") {

	// 	}
	// });


    $(window).resize(function(){
    distribution_blocks();
    if ($('.home-slider').length ) {
      home_slider_resize();
    }
		_gridSize = breakpoint().match(/(\d+)/g);
		_winSize = $(window).width();
		_margin = (_winSize - _gridSize) / 2;

		_gridLabel = breakpoint().replace(/\d+/g, '').replace(' ','');

		if(_gridLabel == "smartphone") {

		}

  }).resize();
  
  $('document').ready(function(){
    $('.vy_listing_produit_items .vy_listing_item a').click(function(){
      $('.vy_listing_produit_items .vy_listing_item').removeClass('active');
      $(this).parent().addClass('active');
      $('.vy_listing_detail').hide();
      $('.vy_listing_detail[id='+$(this).attr('data-id')+']').fadeIn();
    });
    //$('#vy_detail_3').show();

    $('.vy_listing_detail .uk-alert-close').click(function(){
      $(this).parent().hide();
      $('.vy_listing_produit_items .vy_listing_item').removeClass('active');
    });

    distribution_blocks();

    if ($('#modal_popup').length ) {
      UIkit.modal('#modal_popup').show();
    }

    if ($('.home-slider').length ) {
      home_slider_resize();
    }
    
  });

  var type = '';
  
  function distribution_blocks(){
    var windowWidth = $(window).width()+15;

    if(windowWidth > 960 && type!='large'){
      $('.vy_listing_detail').each(function(){
        $(this).insertAfter($('.vy_listing_block[data-block='+$(this).attr('data-position-3')+']'));
      });
      type = 'large';
    }else if(windowWidth > 639 && windowWidth < 959  && type!='medium'){
      $('.vy_listing_detail').each(function(){
        $(this).insertAfter($('.vy_listing_block[data-block='+$(this).attr('data-position-2')+']'));
      });
      type = 'medium';
    }else if(windowWidth < 640 && type!='small'){
      $('.vy_listing_detail').each(function(){
        $(this).insertAfter($('.vy_listing_block[data-block='+$(this).attr('data-position-1')+']'));
      });
      type = 'small';
    }
    
  }

  function home_slider_resize(){
    $('.home-slider .uk-slideshow-items .uk-active').css('height',$(this).find('.item').height());
    $('.home-slider').css('height',$('.home-slider .uk-slideshow-items .uk-active .item').height());
  }

});
